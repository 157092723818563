<template>
  <div />
</template>

<script>
  export default {
    name: "SelectProductInput"
  }
</script>

<style scoped>

</style>